import React, { ReactNode } from 'react'

import CloseIcon from 'src/components/icons/Close'
import { DataTestIds } from 'src/config/dataTestIds'

import * as S from './Card.styles'

export const Card = ({
  children,
  onClick,
  className,
  onClose,
  Title,
  dataTestId,
  ...rest
}: {
  className?: string
  children: ReactNode | ReactNode[]
  onClick?: () => void
  onClose: (e) => void
  Title: ReactNode
  dataTestId?: string
}) => {
  return (
    <S.Root className={className} onClick={onClick} data-testid={dataTestId} {...rest}>
      <S.Wrapper>
        <S.Header>{Title}</S.Header>

        <S.IconButton onClick={onClose} data-testid={DataTestIds.DismissButton}>
          <CloseIcon />
        </S.IconButton>
      </S.Wrapper>

      {children}
    </S.Root>
  )
}
