import React from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { DialogNames } from 'src/components/dialogs/DialogRoot'
import { setSourceReferenceId, useAnaDispatch } from 'src/components/providers/AnaProvider'
import {
  setAnamnesisWarningDismiss,
  useAuthorizedContext,
  useAuthorizedDispatch,
} from 'src/components/providers/AuthorizedProvider'
import { useDialog } from 'src/components/providers/DialogProvider'
import { useAnamnesisEvaluation } from 'src/hooks/useAnamnesisEvaluation'
import { AnaMode } from 'src/hooks/useAnaNavigation'

import { DashboardWarning } from '../DashboardWarning/DashboardWarning'

export const AnamnesisWarning = () => {
  const { t } = useTranslation()
  const unauthorizedDispatch = useAuthorizedDispatch()
  const anaDispatch = useAnaDispatch()
  const { anamnesisWarningDismissed } = useAuthorizedContext()
  const router = useRouter()

  const { all, expired, missing, mostRecentValidAna } = useAnamnesisEvaluation({ enabled: !anamnesisWarningDismissed })

  const { openDialog, closeDialog } = useDialog()

  const [firstExpiredAnaReferenceId] = expired
  const [firstMissingAnaClinicReferenceId] = missing

  const onlyOneMissing = missing.length === 1 && expired.length === 0
  const onlyOneExpired = expired.length === 1 && missing.length === 0

  const listingRedirect = `/anamnesis`

  const redirect = (() => {
    if (onlyOneExpired) return `/anamnesis/${firstExpiredAnaReferenceId}/${AnaMode.RENEW}`

    if (onlyOneMissing) return `/anamnesis/${firstMissingAnaClinicReferenceId}/${AnaMode.NEW}`

    return listingRedirect
  })()

  const ctaLabel = (() => {
    if (missing.length) return 'b2c.anamnesis.dashboardAlertRedirectMissing'

    return 'b2c.anamnesis.dashboardAlertRedirect'
  })()

  const title = (() => {
    if (onlyOneExpired) return 'b2c.anamnesis.dashboardAlertTitleExpiredInOneClinic'

    if (onlyOneMissing) return 'b2c.anamnesis.dashboardAlertTitleMissingInOneClinic'

    if (missing.length) return 'b2c.anamnesis.dashboardAlertTitleMissing'

    return 'b2c.anamnesis.dashboardAlertTitle'
  })()

  const clinicOfficialName = all.find(({ anamnesisReferenceId, clinicReferenceId }) => {
    return onlyOneExpired
      ? anamnesisReferenceId === firstExpiredAnaReferenceId
      : clinicReferenceId === firstMissingAnaClinicReferenceId
  })?.clinicOfficialName

  const performRedirect = () => router.push(redirect)

  return (
    <DashboardWarning
      redirect={{ to: redirect, label: t(ctaLabel) }}
      onClick={() => {
        if (!mostRecentValidAna || redirect === listingRedirect) {
          return performRedirect()
        }

        openDialog(DialogNames.AnaSourceDialog, {
          onSubmit: ({ sourceReferenceId }: { sourceReferenceId: string }) => {
            closeDialog()
            anaDispatch(setSourceReferenceId(sourceReferenceId))
            performRedirect()
          },
        })
      }}
      title={t(title, { clinicOfficialName })}
      onClose={() => unauthorizedDispatch(setAnamnesisWarningDismiss(true))}
      hidden={anamnesisWarningDismissed || expired.length + missing.length === 0}
    />
  )
}
