import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Text = styled(Typography)({
  color: colors.grey[700],
})

Text.defaultProps = {
  variant: 'Lato Body 2',
}

export const Image = styled('img')({
  height: 'auto',
  objectFit: 'contain',
})

Image.defaultProps = {
  src: '/privadent/meditation.png',
}

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))
