import React, { useEffect, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { useLocalStorage } from '@dentalux/common'

import { Button, Typography } from '@mui/material'

import { RedPill } from 'src/components/RedPill/RedPill'
import { SUPPLEMENTARY_INSURANCE_DISMISSAL_AT_KEY } from 'src/hooks/api/useSupplementaryInsuranceDismissal'
import useIsDesktop from 'src/hooks/useIsDesktop'

import { Card } from '../Card/Card'
import Section from '../Section'

import * as S from './SupplementaryInsuranceSection.styles'

export const SupplementaryInsuranceSection = () => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const router = useRouter()
  const [, setValue] = useLocalStorage<string>(SUPPLEMENTARY_INSURANCE_DISMISSAL_AT_KEY)
  const [dismissed, setDismissed] = useState(false)

  const supplementaryInsuranceRedirect = '/supplementary-insurance'

  useEffect(() => {
    router.prefetch(supplementaryInsuranceRedirect)
  }, [router.prefetch])

  if (dismissed) {
    return null
  }

  return (
    <Section
      title={t('b2c.home.subheading.insurance')}
      extra={<RedPill>{t('b2c.home.subheading.insurancePill')}</RedPill>}
    >
      <Card
        onClose={() => {
          setDismissed(true)
          setValue(new Date().toISOString())
        }}
        Title={
          <Typography variant={isDesktop ? 'Lato H5 Bold' : 'Lato Emphasized 1'}>
            {t('b2c.home.subheading.insuranceTitle')}
          </Typography>
        }
      >
        <S.Content>
          <S.Text>{t('b2c.home.insurance.body')}</S.Text>
          <S.Image width={84} />
        </S.Content>

        <Button size="sm" onClick={() => router.push(supplementaryInsuranceRedirect)} variant="bordered">
          {t('b2c.home.insurance.button')}
        </Button>
      </Card>
    </Section>
  )
}
