import { Box, IconButton as BaseIconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
}))

export const Wrapper = styled(Box)({
  justifyContent: 'space-between',
})

Wrapper.defaultProps = {
  display: 'flex',
  gap: 2,
}

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

export const IconButton = styled(BaseIconButton)({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
})
